<template>
  <section name="cargos">
    <v-card elevation="2" rounded class="ma-8 pa-4">
      <v-row>
        <v-col cols="12">
          <Crud
            title="Gestão de Competências"
            buttonAddItemText="Nova Competências"
            searchLabelText="Pesquisar na lista"
            :searchTextField.sync="searchTextField"
            :formTitle="nameForm"
            :callBackModalCancelar="fechar"
            :dialog.sync="dialog"
            :callBackModalSalvar="salvar"
            btnNovo="Comportamentos"
            btnNovoIcone="mdi-human-greeting-variant"
            btnNovoRota="avd-comportamentos-view"
          >
            <template slot="data-content">
              <v-row class="mt-5">
                <!-- FILTRO CAMPOS -->
              </v-row>
            </template>
            <template slot="data-table">
              <v-data-table
                :headers="headers"
                :items="competencias"
                class="elevation-5"
                :loading="loadingTable"
              >
                <!-- ACTIONS -->
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        :loading="loadingVisualizar[`${item.id}`]"
                      >
                        <v-icon
                          class="actions"
                          @click="toggleModalLateral(item)"
                        >
                          mdi-eye
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Visualizar</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        :loading="loadingEditar[`${item.id}`]"
                      >
                        <v-icon class="actions" @click="editar(item)">
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        :loading="loadingDelete[`${item.id}`]"
                      >
                        <v-icon class="actions" @click="remover(item)">
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Deletar</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.icone`]="{ item }">
                  <v-icon>
                    {{ item.icone }}
                  </v-icon>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-switch
                    class="mt-0 pt-0"
                    v-model="item.status"
                    @click="mudarStatus(item)"
                    :label="item.status == 'A' ? 'Ativo' : 'Inativo'"
                    color="success"
                    value="A"
                    hide-details
                    :loading="loadingStatus[`${item.id}`]"
                  ></v-switch>
                </template>
                <template v-slot:[`item.padrao`]="{ item }">
                  <v-chip small color="primary">
                    {{ item.padrao }}
                  </v-chip>
                </template>
                <template v-slot:[`item.created_at`]="{ item }">
                  {{ item.created_at | dateFormatBr() }}
                </template>
              </v-data-table>
            </template>
            <!-- ------------- MODAL DE CADASTRO --------------------------------------------- -->
            <template slot="contentModalCrud">
              <v-container fluid>
                <v-row class="ma-3" wrap>
                  <!-- Nome -->
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      ref="nome"
                      label="Nome do Competêcia"
                      placeholder="Escreva o nome do competencia"
                      required
                      outlined
                      dense
                      v-model="competencia.nome"
                      data-vv-as="nome"
                      persistent-hint
                      :hint="errors.first('nome')"
                      :error="errors.collect('nome').length ? true : false"
                      v-validate="'required|min:3'"
                      data-vv-validate-on="change"
                      id="nome"
                      name="nome"
                    ></v-text-field>
                  </v-col>
                  <!-- Sigla -->
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      ref="sigla"
                      label="Sigla"
                      placeholder="Escreva o sigla da competencia"
                      required
                      outlined
                      dense
                      v-model="competencia.sigla"
                      data-vv-as="sigla"
                      persistent-hint
                      :hint="errors.first('sigla')"
                      :error="errors.collect('sigla').length ? true : false"
                      v-validate="'required|length:3'"
                      data-vv-validate-on="change"
                      id="sigla"
                      name="sigla"
                    ></v-text-field>
                  </v-col>
                  <!-- Descrição -->
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      ref="descricao"
                      label="Descrição"
                      outlined
                      dense
                      v-model="competencia.descricao"
                      data-vv-as="descrição"
                      persistent-hint
                      :hint="errors.first('descricao')"
                      :error="errors.collect('descricao').length ? true : false"
                      v-validate="'required'"
                      data-vv-validate-on="change"
                      id="descricao"
                      name="descricao"
                    ></v-textarea>
                  </v-col>
                  <!-- Icone -->
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      ref="icone"
                      label="Icone"
                      placeholder="Escreva o icone da competencia"
                      v-model="competencia.icone"
                      :items="SelectItensIcons"
                      outlined
                      dense
                      item-text="icone"
                      item-value="icone"
                      data-vv-as="icone"
                      persistent-hint
                      :hint="errors.first('icone')"
                      :error="errors.collect('icone').length ? true : false"
                      v-validate="'required'"
                      data-vv-validate-on="change"
                      id="icone"
                      name="icone"
                    >
                      <template v-slot:selection="data">
                        <v-icon large color="gray darken-2">
                          {{ data.item }}
                        </v-icon>
                      </template>
                      <template v-slot:item="data">
                        <template>
                          <v-icon large color="grey darken-2">
                            {{ data.item }}
                          </v-icon>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!-- Tipo -->
                  <v-col cols="12" sm="12" md="6">
                    <v-select
                      ref="tipo"
                      label="Tipo"
                      placeholder="Selecione o tipo da competencia"
                      v-model="competencia.tipo"
                      :items="SelectItensTipos"
                      outlined
                      dense
                      item-text="text"
                      item-value="value"
                      data-vv-as="tipo"
                      persistent-hint
                      :hint="errors.first('tipo')"
                      :error="errors.collect('tipo').length ? true : false"
                      v-validate="'required'"
                      data-vv-validate-on="change"
                      id="tipo"
                      name="tipo"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </Crud>
        </v-col>
      </v-row>
      <ModalLateral
        :show="modalLateral.show"
        :hide="toggleModalLateral"
        :title="modalLateral.title"
        :headers="headerModalLateral"
        :item="modalLateral.item"
      >
        <template v-slot:[`item.icone`]="{ item }">
          <v-icon>{{ item.icone }}</v-icon>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip :color="getColor(item.status)" small dark>
            {{
              item.status == "A" || item.status == "Ativo" || item.status == ""
                ? "Ativo"
                : "Inativo"
            }}
          </v-chip>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          <span
            >{{ item.created_at | dateFormatBr() }}
            {{ item.created_at | timeFormatBr() }}</span
          >
        </template>
        <template v-slot:[`item.padrao`]="{ item }">
          <v-chip small color="primary">
            {{ item.padrao }}
          </v-chip>
        </template>
      </ModalLateral>
    </v-card>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="#001325" size="75">
        <img :src="logotipo" height="45" alt="" />
      </v-progress-circular>
    </v-overlay>
  </section>
</template>

<script>
import logo from "@/assets/images/logos/legiio.png";
import Crud from "@/components/Crud.vue";
import ModalLateral from "@/components/ModalLateral.vue";

export default {
  name: "Competencias",
  inject: ["$validator"],
  components: {
    Crud,
    ModalLateral,
  },
  data: () => ({
    nameForm: "Nova Competência",
    modalLateral: {
      show: false,
      item: [],
      title: "",
    },
    competencia: {
      id_empresa: "",
      id_filial: "",
      nome: "",
      descricao: "",
      sigla: "",
      icone: "",
      tipo: "",
      padrao: "",
      status: "",
    },
    SelectItensStatus: [
      { text: "Ativo", value: "A" },
      { text: "Inativo", value: "I" },
    ],
    SelectItensIcons: [
      "mdi-domain",
      "mdi-message-text",
      "mdi-email",
      "mdi-call-split",
      "mdi-arrow-up-bold-box-outline",
      "mdi-checkbox-marked-circle",
      "mdi-cancel",
      "mdi-minus-circle",
      "mdi-cancel",
      "mdi-arrow-left",
      "mdi-wrench",
      "mdi-cloud-upload",
      "mdi-thumb-up",
      "mdi-thumb-down",
      "mdi-account-cash",
      "mdi-access-point",
      "mdi-account-details",
      "mdi-account-supervisor",
      "mdi-all-inclusive",
      "mdi-alphabetical-variant",
      "mdi-android-messages",
      "mdi-dna",
      "mdi-attachment",
      "mdi-badge-account-horizontal",
      "mdi-bag-suitcase",
      "mdi-basketball",
      "mdi-battery-charging-high",
      "mdi-beach",
      "mdi-bell",
      "mdi-boom-gate",
      "mdi-bulletin-board",
      "mdi-cake-variant",
      "mdi-calendar-check",
      "mdi-chart-pie",
      "mdi-chair-rolling",
      "mdi-controller-classic",
    ],
    SelectItensTipos: [
      { text: "Comportamental", value: "comportamental" },
      { text: "Técnico", value: "tecnico" },
    ],
    searchTextField: "",
    dialog: false,
    indexEdicao: -1,
    loadingTable: false,
    loadingVisualizar: [],
    loadingEditar: [],
    loadingDelete: [],
    loadingStatus: [],
    logotipo: logo,
    headers: [
      { text: "Nome", align: "start", value: "nome" },
      { text: "Sigla", value: "sigla" },
      { text: "Icone", value: "icone" },
      { text: "Padrão", value: "padrao" },
      { text: "Status", value: "status" },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    headerModalLateral: [
      { text: "Descrição", value: "descricao" },
      { text: "Sigla", value: "sigla" },
      { text: "Icone", value: "icone" },
      { text: "Empresa", value: "id_empresa" },
      { text: "Padrão", value: "padrao" },
      { text: "Data de criação", value: "created_at" },
      { text: "Status", value: "status" },
    ],
    dialogPerfil: false,
  }),

  computed: {
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
    formTitle() {
      return this.indexEdicao === -1
        ? "Cadastrar competencia"
        : "Editar competencia";
    },
    competencias: {
      get() {
        return this.$store.getters["empresas/getEmpresaCompetencias"];
      },
    },
    loading: {
      get() {
        return this.$store.getters["empresas/getLoading"];
      },
      set(value) {
        return value;
      },
    },
  },

  created() {
    if (this.$route.params.uuid === undefined) {
      this.$store.dispatch("empresas/buscarCompetenciasInitialize");
    } else {
      this.$store.dispatch("empresas/buscarCompetencias", {
        uuid: this.$route.params.uuid,
      });
    }
  },

  methods: {
    salvar() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.dialog = false;
          this.loadingTable = true;
          let url =
            this.indexEdicao === -1
              ? "/competencias"
              : "/competencias/" + this.competencia.uuid;
          let method = this.indexEdicao === -1 ? "POST" : "PUT";
          let sucesso =
            this.indexEdicao === -1
              ? "Competência cadastrado com sucesso."
              : "Competência atualizado com sucesso.";
          let erro =
            this.indexEdicao === -1
              ? "Erro ao salvar competencia."
              : "Erro ao atualizar competencia.";
          if (this.competencia.id_filial == null) {
            this.competencia.id_filial = 0;
            this.competencia.filial = { id: null, nome: "Matriz" };
          }
          let competenciaItem = this.competencia;
          competenciaItem._method = method;
          competenciaItem.url = url;
          competenciaItem.padrao = "personalizado";
          this.$store
            .dispatch("empresas/salvarEmpresaCompetencia", competenciaItem)
            .then((response) => {
              if (response != "NAO_AUTORIZADO") {
                this.indexEdicao === -1
                  ? this.competencias.push(response)
                  : Object.assign(
                      this.competencias[this.indexEdicao],
                      this.competencia
                    );
                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  mensagem: sucesso,
                  cor: "green",
                });
              }
            })
            .catch((error) => {
              console.error(error);
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                mensagem: erro,
                cor: "red",
              });
            })
            .finally(() => {
              this.fechar();
            });
        } else {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Preencha todo formulário antes de salvar!",
            cor: "red",
          });
        }
      });
    },
    editar(item) {
      if (item.updated_at == null || item.updated_at == undefined) {
        this.loadingEditar[item.id] = true;
        this.$store
          .dispatch("empresas/buscarCompetenciaSelecionada", item.uuid)
          .then((response) => {
            if (response.data.id_filial == null) {
              response.data.id_filial = 0;
              response.data.filial = { id: null, nome: "Matriz" };
            }
            this.indexEdicao = this.competencias.indexOf(item);
            Object.assign(this.competencias[this.indexEdicao], response.data);
            this.competencia = Object.assign({}, response.data);
            this.nameForm = "Editar competência: " + this.competencia.nome;
            this.dialog = true;
          })
          .catch(() => {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem:
                "Houve um erro ao buscar todos os dados da competência selecionada.",
              cor: "red",
            });
          })
          .finally(() => {
            this.loadingEditar[item.id] = false;
          });
      } else {
        this.nameForm = "Editar competência: " + item.nome;
        this.indexEdicao = this.competencias.indexOf(item);
        this.competencia = Object.assign({}, item);
        this.dialog = true;
      }
    },
    fechar() {
      this.dialog = false;
      (this.loadingTable = false), (this.nameForm = "Nova competência");
      this.competencia = {
        id_empresa: "",
        id_filial: "",
        nome: "",
        descricao: "",
        sigla: "",
        icone: "",
        tipo: "",
        padrao: "",
        status: "",
      };
    },
    remover(item) {
      const index = this.competencias.indexOf(item);
      this.$swal
        .fire({
          title: "Deletar",
          text: `Tem certeza que deseja deletar competencia: ${item.nome} ?`,
          icon: "warning",
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonColor: "#1976d2",
          confirmButtonText: "Sim, quero deleta-lo!",
          cancelButtonColor: "#555",
          cancelButtonText: "Cancelar",
        })
        .then((response) => {
          if (response.isConfirmed) {
            this.loadingTable = true;
            this.loadingDelete[item.id] = true;
            this.$store
              .dispatch("empresas/deletarEmpresaCompetencia", item.uuid)
              .then((response) => {
                if (response != "NAO_AUTORIZADO") {
                  this.competencias.splice(index, 1);

                  this.$swal.fire({
                    text: `Competência deletada com sucesso!`,
                    icon: "sucess",
                    showCloseButton: true,
                    confirmButtonColor: "#1976d2",
                    confirmButtonText: "Fechar",
                  });
                }
              })
              .catch(() => {
                this.$swal.fire({
                  text: `Houve um erro ao deletar o Competencia! Favor tente novamente mais tarde.`,
                  icon: "error",
                  showCloseButton: true,
                  confirmButtonColor: "#1976d2",
                  confirmButtonText: "Fechar",
                });
              })
              .finally(() => {
                this.loadingTable = false;
                this.loadingDelete[item.id] = false;
              });
          }
        });
    },
    mudarStatus(item) {
      this.loadingStatus[item.id] = true;
      this.loadingTable = true;

      this.indexEdicao = this.competencias.indexOf(item);
      this.competencia = Object.assign({}, item);
      this.competencia.status =
        this.competencia.status == null ? "I" : this.competencia.status;
      if (this.competencia.id_filial == null) {
        this.competencia.id_filial = 0;
        this.competencia.filial = { id: null, nome: "Matriz" };
      }
      let competenciaItem = this.competencia;
      competenciaItem._method = "PUT";
      competenciaItem.url = "/competencias/" + this.competencia.uuid;
      this.$store
        .dispatch("empresas/salvarEmpresaCompetencia", competenciaItem)
        .then((response) => {
          if (response != "NAO_AUTORIZADO") {
            Object.assign(
              this.competencias[this.indexEdicao],
              this.competencia
            );
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem: "Competência atualizada com sucesso.",
              cor: "green",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Erro ao atualizar competência.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadingStatus[item.id] = false;
          this.loadingTable = false;
          this.indexEdicao = -1;
          this.competencia = Object.assign(
            {},
            {
              id_empresa: "",
              id_filial: "",
              nome: "",
              descricao: "",
              sigla: "",
              icone: "",
              tipo: "",
              padrao: "",
              status: "",
            }
          );
        });
    },
    //Modal Lateral
    handleClickRow(value) {
      this.toggleModalLateral(value);
    },
    toggleModalLateral(item) {
      if (this.modalLateral.show == false) {
        if (item.updated_at == null || item.updated_at == undefined) {
          this.loadingVisualizar[item.id] = true;
          this.$store
            .dispatch("empresas/buscarCompetenciaSelecionada", item.uuid)
            .then((response) => {
              const index = this.competencias.indexOf(item);
              if (response.data.id_filial == null) {
                response.data.id_filial = 0;
                response.data.filial = { id: null, nome: "Matriz" };
              }
              Object.assign(this.competencias[index], response.data);
              this.modalLateral.title = item.nome;
              this.modalLateral.item = item ? item : [];
              this.modalLateral.show = true;
            })
            .catch(() => {
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                mensagem:
                  "Houve um erro ao buscar todos os dados da competência selecionada.",
                cor: "red",
              });
            })
            .finally(() => {
              this.loadingVisualizar[item.id] = false;
            });
          return;
        } else {
          this.modalLateral.title = this.modalLateral.show ? "" : item.nome;
          this.modalLateral.item = this.modalLateral.show ? [] : item;
          this.modalLateral.show = this.modalLateral.show ? false : true;
        }
      } else {
        this.modalLateral.show = false;
      }
    },
    //DATATABLE COSTUMIZATION
    getColor(status) {
      if (status == "A" || status == "Ativo") return "#56ca00";
      else return "#ff4c51";
    },
  },
};
</script>

<style scoped></style>
